body:not(.homepage) {
    .header-container {
        background: white !important;
    }
}

body {
    &.homepage {
        .header-slider-content {
            .header-slider-title {
                font-family: "Academy Engraved LET";
                font-weight: bold;
                font-style: italic;
                text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
                margin-left: 40px;
            }
        }

        .header-slider-content {
            margin: 25px 25px 0 0;
            text-align: left;
        }
    }

    .header-container {
        .btn-menu {
            .menu-text {
                color: color('primary');
            }

            .menu-icon > span {
                background-color: color('primary');
            }
        }

        .header-nav-top {
            background-color: #269AC4 !important;
            text-align: left;
        }

        .header-nav-main {
            background: #FFF !important;

            .img-fluid {
                height: 55px;
                margin: 18px;
            }
        }
    }

    .header-container.fixed-top.scrolled {
        background: white !important;
    }

    .header-container.fixed-top {
        background: transparent;
    }

    .nav-link {
        color: #5E5E5E;
        padding: 0 20px !important;
    }

    .nav-link:hover {
        color: #4C90A4;
    }

    .header-container .header-nav-main .header-nav .nav-item.active {
        border-bottom: none !important;
    }

    .footer-section {
        background: #3F464D !important;
    }

    .main-footer {
        .footer-section.footer-main {
            .footer-nav ul li a {
                color: #D2D3D4;
            }

            .footer-contact {
                li {
                    color: #D2D3D4 !important;

                    a {
                        color: #D2D3D4 !important;
                    }
                }
            }
        }

        .footer-section.footer-bottom {
            color: #9EA0A3 !important;
        }

        .footer-section {
        }

        h4.block-title::after {
            background: #269AC4 !important;
            height: 2px !important;
        }
    }

    .social-icons {
        margin-left: -3px;

        .social-icon {
            border: none !important;
            width: 28px !important;
            height: 28px !important;
        }
    }

    @media (max-width: 991px) {
        .social-media {
            display: flex;
            justify-content: center;
        }
    }

    .social-media {
        .a2a_svg {
            border: 1px solid dimgray !important;
            height: 30px !important;
            width: 30px !important;
        }

        a:hover {
            svg path {
                fill: #3F464D !important;
            }
        }
    }

    .list-inline-item {
        label {
            background-color: #269AC4 !important;

            span {
                color: #FFFFFF !important;
            }
        }
    }

    .scroll-icon {
        bottom: 50px !important;
        box-shadow: inset 0 0 0 2px #fff;
    }

    .contact-info i {
        color: white !important;
    }

    .company-name {
        display: none;
    }
}

